// import logo from './logo.svg';
import './App.css';
// font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// internal
// import Response from './components/Response'
import Prompt from './components/Prompt';

function App() {
  return (
    <>
      <Prompt />
    </>
  );
}

export default App;
